
<script lang="ts">
    export interface IProps {
        /* href for redirect */
        href?: string;
        /* prop */
        type?: string;
        /* prop */
        title?: string;
        /* prop */
        pills?: Array<IPill>;
        /* prop */
        startDate?: Date | string;
        /* prop */
        finishDate?: Date | string;
        /* prop */
        shadow?: "none" | "sm" | "md" | "lg";
        /* prop */
        ribbonColor?: string;
        /* prop */
        strikethrough?: boolean;
        /* prop */
        orgUnit?: string;
        /* prop */
        object?: string;
        /* prop */
        person?: string;
        /* prop */
        role?: string;
        /* prop */
        relation?: string;
        /* prop */
        date?: string;

        /* internal */
        onClick?: any;
    };

    export interface IPill {
        color: string;
        cssColor: string;
        text: string;
        ribbon?: boolean;
    };


    export interface IEmits {
        (e: "click"): void;
    };
</script>

<script setup lang="ts">
    import { computed } from "vue";
    import { utils } from "o365-utils";

    import colors, { getColor } from "./colors.ts";
    import MPill from "./components.Pill.vue";

    const props = defineProps<IProps>();
    const emit = defineEmits<IEmits>();

    const isClickable = computed(() => props.href || props.onClick);
    const pills = computed<Array<IPill>>(() => props.pills ?? []);
    const ribbonPill = computed<IPill | undefined>(() => pills.value.find(e => e.ribbon !== false));
    const startDate = computed(() => utils.getValidDate(props.startDate));
    const finishDate = computed(() => utils.getValidDate(props.finishDate));

    const ribbonColor = computed(() => {
        if (props.ribbonColor) {
            return props.ribbonColor;
        } else if (ribbonPill.value != null) {
            return getColor(ribbonPill.value.cssColor ?? ribbonPill.value.color).background;

            // let ribbonPillCssColor = ribbonPill.value.cssColor;
            // let ribbonPillColor =  ribbonPill.value.color;

            // console.log("ribbonPillCssColor", ribbonPillCssColor);

            // return (ribbonPillCssColor || colors[ribbonPillColor] || colors["danger"]).background;
        }
    });

    const style = computed(() => {
        const style = {};
        if (props.shadow !== "none") {
            style.boxShadow = "2px 4px 8px rgba(0%, 0%, 0%, 5%)";
        }
        return style;
    });
</script>

<template>
    <component :is="props.href && 'a' || 'div'" class="afm-card" :class="{ 'afm-card-clickable': isClickable }" :style="style" :href="props.href" v-bind="$attrs" @click="(...args) => emit('click', ...args)">
        <template v-if="ribbonColor">
            <div class="flex-shrink-0" :style="{ 'background-color': ribbonColor, width: '6px', 'border-radius': '0.5rem 0 0 0.5rem' }">
            </div>
        </template>
        <div class="flex-grow-1" style="padding: 0.75rem; min-width: 0;">
            <template v-if="type">
                <div class="font-n4 uppercase tracking-4 text-muted">
                    {{ type }}
                </div>
            </template>
            <template v-if="title">
                <div id="title" class="font-1 fw-medium" :class="{ 'text-primary': isClickable, 'text-decoration-line-through': strikethrough }">
                    {{ title }}
                </div>
            </template>
            <template v-if="pills?.length">
                <div class="d-flex gap-1" style="margin-top: 0.125rem; margin-bottom: 0.125rem; margin-left: -0.125rem;">
                    <template v-for="pill in pills">
                        <MPill size="xs" :cssColor="pill.cssColor" :color="pill.color" :text="pill.text" uppercase />
                    </template>
                </div>
            </template>
            <template v-if="orgUnit">
                <div class="d-flex align-items-center" style="gap: 0.125rem;">
                    <!-- <i class="bi bi-diagram-3 font-2 stroke-2 text-muted"/> -->
                    <OButton variant="link" color="muted" icon="bi-diagram-3 font-2 stroke-2" />
                    <span class="font-n2 text-truncate">{{ $t("OrgUnit") }}: {{ orgUnit }}</span>
                </div>
            </template>
            <template v-if="object">
                <div class="d-flex align-items-center" style="gap: 0.125rem;">
                    <!-- <i class="bi bi-tag font-2 stroke-2 text-muted" /> -->
                    <OButton variant="link" color="muted" icon="bi-tag font-2 stroke-2" />
                    <span class="font-n2 text-truncate">{{ $t("Object") }}: {{ object }}</span>
                </div>
            </template>
            <template v-if="person">
                <div class="d-flex align-items-center" style="gap: 0.125rem;">
                    <!-- <i class="bi bi-person font-2 stroke-2 text-muted" /> -->
                    <OButton variant="link" color="muted" icon="bi-person font-2 stroke-2" />
                    <span class="font-n2 text-truncate">{{ [relation, person].filter(e => e).join(": ") }}</span>
                </div>
            </template>
            <template v-else-if="role">
                <div class="d-flex align-items-center" style="gap: 0.125rem;">
                    <!-- <i class="bi bi-people font-2 stroke-2 text-muted" /> -->
                    <OButton variant="link" color="muted" icon="bi-people font-2 stroke-2" />
                    <span class="font-n2 text-truncate">{{ [relation, role].filter(e => e).join(": ") }}</span>
                </div>
            </template>
            <template v-if="date">
                <div class="d-flex align-items-center" style="gap: 0.125rem;">
                    <!-- <i class="bi bi-calendar-event"></i> -->
                    <OButton variant="link" color="muted" icon="bi-calendar-event font-1 stroke-1" />
                    <div class="font-n2 text-truncate">{{ date }}</div>
                </div>
            </template>
            <slot />
            <template v-if="startDate || finishDate">
                <div class="d-flex justify-content-end">
                    <div class="d-flex align-items-center gap-1">
                        <div>{{ $formatDate(startDate) }}</div>
                        <i class="bi bi-arrow-right" />
                        <div>{{ $formatDate(finishDate) }}</div>
                    </div>
                </div>
            </template>
        </div>
    </component>
</template>

<style scoped>
    .afm-card {
        display: flex;
        position: relative;

        color: black;
        background-color: white;
        background-color: var(--bs-white);
        border: 1px solid rgb(90%, 90%, 90%);
        border: 1px solid var(--bs-gray-300);

        text-decoration: none;

        border-radius: 0.5rem;
    }

    .afm-card-clickable {
        transition: background 200ms ease-in-out;
        &:active {
            transition: background 0ms;
            background-color: var(--bs-gray-300);
        }
    }

    .afm-card:before {
        position: absolute;
        inset: 0;
        background-color: rgb(50%, 50%, 50%);
        opacity: 0%;
        transition: all 200ms ease-in-out;
    }
    .afm-card:hover:before {
        opacity: 10%;
    }

    #title{
        -webkit-line-clamp: 2;
         /* autoprefixer: off */
        -webkit-box-orient: vertical;
         /* autoprefixer: on */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
    }
</style>
